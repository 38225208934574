import {createTheme, responsiveFontSizes} from "@mui/material";

export const daciaTheme = responsiveFontSizes(createTheme({
    typography: {
        fontFamily: ["Dacia Block", "sans-serif"].join(","),
    },
    components: {
        MuiFormControlLabel: {
            styleOverrides: {
                asterisk: {
                    display:'none'
                }
            }
        }
    },
    palette: {
        mode: 'light',
        primary: {
            main: '#636B51',
        }
    }
}))
