import React from 'react';
import {Box, Typography} from "@mui/material";

export const ContenutoVideo = ({material}) => {
    return(
        <Box sx={{py:2}}>
            <Box sx={{width:'min(35rem, 100%)'}}>
                <div style={{padding: "56.25% 0 0 0", position: "relative"}}>
                    <iframe
                        src={material.link}
                        frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen
                        style={{position: "absolute", top: "0", left: "0", width: "100%", height: "100%"}} title="BENVENUTO - Rudy Bandiera introduce il programma Dacia Empowerment"></iframe>
                </div>
            </Box>
            <Box sx={{pt:2}}>
                <Typography fontFamily={'Dacia Block Extended'}>
                    {material.title}
                </Typography>
                <Typography sx={{py:1}}>
                    {material.description}
                </Typography>
                <Typography color={'#848484'}>
                    Caricato il {material.uploadedAt}
                </Typography>
            </Box>
        </Box>
    )
}
