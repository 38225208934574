import React from "react";
import {Box} from "@mui/material";

export const Footer = () => {
  return(
      <Box sx={{lineHeight: '0'}}>
          <Box sx={{width:'100%', height:'min(20vw, 7rem)'}}>
              <img style={{width:'100%', height:'100%', objectFit:'cover'}} src={require('../../assets/images/footer-2.jpg')}/>
          </Box>
      </Box>
  )
}
