import React from "react";
import {Box, Button, Card, CardContent, Container, Stack, TextField, Typography, useTheme} from "@mui/material";

export const LoginPage = ({children, text}) => {
    const theme = useTheme()

    return (
        <Box class={'login-page'}>
            <Container maxWidth={'sm'}>
                <Stack spacing={4}>
                    <Box sx={{textAlign: 'center'}}>
                        <img style={{width: '80%'}} src={require('../assets/images/event-logo.png')}/>
                    </Box>
                    <Card>
                        <CardContent>
                            <Typography gutterBottom fontFamily={'Dacia Block Extended'} textAlign={'center'}>
                                {text}
                            </Typography>
                            <Typography variant={'body2'} textAlign={'center'} sx={{pb: 1}}>
                                (Utilizza l'e-mail alla quale hai ricevuto l'ultima comunicazione)
                            </Typography>
                            <Box sx={{
                                width: '6rem',
                                height: '0.5rem',
                                margin: 'auto',
                                background: theme.palette.primary.main
                            }}/>
                            {children}
                        </CardContent>
                    </Card>
                    <Box sx={{textAlign: 'center'}}>
                        <img style={{width: '80%'}} src={require('../assets/images/dacia-logo-white.png')}/>
                    </Box>
                </Stack>
            </Container>
        </Box>
    )
}
