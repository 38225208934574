import React from "react";
import {AppBar, Box, Button, CircularProgress, Toolbar} from "@mui/material";
import {auth} from "../../firebase/clientApp";
import {getAuthToken} from "../../functions/getAuthToken";
import axios from "axios";
import {API_URL} from "../../config";
import {Logout} from "@mui/icons-material";

export const Header = () => {
    const [loggingOut, setLoggingOut] = React.useState(false);
    const doLogout = async () => {
        setLoggingOut(true);
        let token = await getAuthToken()
        await axios.post(`${API_URL}/logout`, {}, {headers: {Authorization: token}})
            .then(res => auth.signOut())
        setLoggingOut(false);
    }
  return(
      <AppBar position={'relative'} sx={{background:'white'}}>
          <Toolbar>
              <Box sx={{flexGrow:'1'}}>
                  <img src={require('../../assets/images/dacia-logo-black.png')} style={{width:'min(20vw, 7rem)'}}/>
              </Box>
              {loggingOut ? <CircularProgress/> : <Button onClick={doLogout} endIcon={<Logout/>}>
                  logout
              </Button>}
          </Toolbar>
      </AppBar>
  )
}
