import './App.css';
import {Box, CssBaseline} from "@mui/material";
import {Route, Routes} from "react-router-dom";
import ProtectedRoute from "./components/shared/ProtectedRoute";
import {Register} from "./pages/Register";
import {Login} from "./pages/Login";
import {Recover} from "./pages/Recover";
import {Error} from "./pages/Error";
import {Webinar} from "./pages/Webinar";
import {Contenuti} from "./pages/Contenuti";
import {AreaPersonale} from "./pages/AreaPersonale";
import TestsContainer from "./pages/TestsContainer";
import {TestGenerico} from "./pages/TestGenerico";

function App() {
    return (
        <Box>
            <CssBaseline/>
            <Routes>
                <Route path={'/register/:id'} element={<Register/>}/>
                <Route path={'/login'} element={<Login/>}/>
                <Route path={'/recover'} element={<Recover/>}/>
                <Route path={'/error'} element={<Error/>}/>
                <Route element={<ProtectedRoute/>}>
                    <Route path={'/'} element={<Webinar/>}/>
                    <Route path={'/test'} element={<TestsContainer/>}/>
                    <Route path={'/test/:session_id'} element={<TestGenerico/>}/>
                    <Route path={'/contenuti'} element={<Contenuti/>}/>
                    <Route path={'/area-personale'} element={<AreaPersonale/>}/>
                </Route>
            </Routes>
        </Box>
    );
}

export default App;
