import React from "react";
import {Box, Card, CardContent, Divider, Stack, Typography} from "@mui/material";
import dayjs from "dayjs";
import {red, green} from "@mui/material/colors";

export const TestBox = ({test, last}) => {
    const {test_passed, total_score, session_id} = test
    return (
        <Card elevation={3}>
            <CardContent>
                <Stack direction={{xs:'column', md:'row'}} justifyContent={'space-between'} alignItems={{xs:'left',md:'center'}}>
                    <Box>
                        {test_passed ?
                            <Typography variant={'h6'} color={green[400]}>Test superato</Typography>
                            :
                            <Typography variant={'h6'} color={red[400]}>Test non superato</Typography>
                        }
                        {/*<Typography>
                            Punteggio:&nbsp;
                            <span style={{fontFamily:'Dacia Block Extended'}}>
                                <span style={{color: test_passed ? 'green' : 'red'}}>{total_score}</span>/100
                            </span>
                        </Typography>*/}
                    </Box>
                    <Box>
                        <Typography variant={'h6'} color={'#848484'}>
                            {dayjs(test.datetime._seconds*1000).format('DD/MM/YYYY')}
                        </Typography>
                        <Typography color={'#848484'}>
                            {dayjs(test.datetime._seconds*1000).format('HH:mm')}
                        </Typography>
                    </Box>
                </Stack>
            </CardContent>
        </Card>
    )
}
