import React, {useEffect} from "react";
import {Box, Button, Typography} from "@mui/material";
import {Download} from "@mui/icons-material";

export const ContenutoPdf = ({material}) => {
    const [description, setDescription] = React.useState([])

    useEffect(() => {
        let d = material.description
        d = d.split('\\n').map((item, key) => {
            return <span key={key}>{item}<br/></span>
        })
        setDescription(d)
    }, [material]);

  return(
      <Box sx={{py:2}}>
          <Box sx={{width:'min(35rem, 100%)'}}>
              <img style={{width:'100%'}} src={material.thumbnail} alt={material.title}/>
          </Box>
          <Box sx={{pt:2}}>
              <Typography fontFamily={'Dacia Block Extended'}>
                  {material.title}
              </Typography>
              <Typography sx={{whiteSpace:'pre-line',pt:1}}>
                  {description}
              </Typography>
              <Box sx={{py:2}}>
                  <Button startIcon={<Download/>} variant={'contained'} href={material.link} target={'_blank'}>
                      download
                  </Button>
              </Box>
              <Typography color={'#848484'}>
                  Caricato il {material.uploadedAt}
              </Typography>
          </Box>
      </Box>
  )
}
