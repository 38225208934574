import React from "react";
import {Box, Divider} from "@mui/material";
import {ContenutoVideo} from "./ContenutiBox/ContenutoVideo";
import {ContenutoPdf} from "./ContenutiBox/ContenutoPdf";

export const ContenutiBox = ({material, last}) => {

    const RenderMaterial = () => {
        switch (material.type) {
            case 'video':
                return <ContenutoVideo material={material}/>
            case 'pdf':
                return <ContenutoPdf material={material}/>
            default:
                return <Box/>
        }
    }

    return(
        <Box>
            <RenderMaterial/>
            {!last && <Divider/>}
        </Box>
    )
}
