import React, {useState} from "react";
import {Box, Card, Collapse, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText} from "@mui/material";
import {Close, ExpandLess, Remove, ExpandMore} from "@mui/icons-material";
import {red} from "@mui/material/colors";

export const WrongAnswer = ({question}) => {
    const [open, setOpen] = useState(false)
    const handleClick = () => {
        setOpen(!open);
    };

    return (
        <Card>
            <ListItemButton onClick={handleClick}>
                <ListItemIcon>
                    <Close sx={{color: red[400]}}/>
                </ListItemIcon>
                <ListItemText primary={question.text}/>
                {open ? <ExpandLess/> : <ExpandMore/>}
            </ListItemButton>
            <Collapse in={open} timeout="auto" unmountOnExit>
                <Divider/>
                <ul style={{textAlign: 'left'}}>
                    {question.answers.map(a =>
                        <li key={a.text} style={{paddingBottom:'0.5rem'}}>
                            {a.text}
                        </li>
                    )}
                </ul>
            </Collapse>
        </Card>
    )
}
