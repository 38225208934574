import {
    Button,
    Card,
    CardContent, IconButton,
    ListItem,
    ListItemSecondaryAction,
    ListItemText,
    Stack,
    Typography,
    useTheme
} from "@mui/material";
import {sessions} from "../config";
import {Platform} from "../layouts/Platform";
import {useNavigate} from "react-router-dom";
import {useUserStore} from "../store/userStore";
import {green, red} from "@mui/material/colors";
import React from "react";
import {OpenInNew} from "@mui/icons-material";

const TestsContainer = () => {
    const theme = useTheme()
    const navigate = useNavigate()
    const {user} = useUserStore()

    function handleGoToTest(session_id) {
        navigate(session_id)
    }

    return (<Platform>
        <Typography fontFamily={'Dacia Block Extended'} variant={'h6'}>Test delle sessioni</Typography>
        <Stack mt={1} spacing={2}>
            {
                sessions.map((session, i) => {
                    return <Card key={i} elevation={3} index={session.id} sx={{width: '100%',}}>
                        <CardContent>
                            <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
                                <>
                                    <Stack>
                                        <Typography variant={'h6'} sx={{
                                            textAlign: 'center',
                                            fontFamily: 'Dacia Block Extended',
                                            color: theme.palette.primary.main
                                        }}>
                                            {session.label}
                                        </Typography>
                                        {session.link && <Stack direction={'row'} alignItems={'center'} justifyContent={'start'}>
                                            <Typography variant={'body2'} children={'Vedi il webinar'}/>
                                            <IconButton size={'small'} onClick={() => {
                                                window.open(session.link, "_blank");
                                            }}>
                                                <OpenInNew/>
                                            </IconButton>
                                        </Stack>}
                                    </Stack>
                                    {user[`lastResult${session.id}`] ?
                                        user[`lastResult${session.id}`].test_passed ?
                                            <Typography textAlign={'center'} sx={{pb: 2}}
                                                        variant={'h6'} color={green[400]}>
                                                Test superato
                                            </Typography>
                                            : <Typography textAlign={'center'} sx={{pb: 2}}
                                                          variant={'h6'} color={red[400]}>
                                                Test non superato
                                            </Typography>
                                        : <Typography textAlign={'center'} sx={{pb: 2}}>
                                            {
                                                "DA SVOLGERE"
                                            }
                                        </Typography>
                                    }
                                </>
                                {
                                    <Button disabled={(user[`lastResult${session.id}`] && user[`lastResult${session.id}`].test_passed)}
                                            onClick={() => handleGoToTest(session.id)} variant={'contained'}>
                                        SVOLGI
                                    </Button>
                                }
                            </Stack>

                        </CardContent>
                    </Card>
                })
            }
        </Stack>
    </Platform>);

}

export default TestsContainer