import React from "react";
import {Stack} from "@mui/material";
import {Footer} from "../components/shared/Footer";

export const WithFooter = ({children}) => {
  return(
      <Stack sx={{minHeight:'100vh', position:'relative'}} justifyContent={'space-between'}>
          {children}
          <Footer/>
      </Stack>
  )
}
