import React from "react";
import {LoginPage} from "../layouts/LoginPage";
import {Box, Button, CircularProgress, Collapse, Container, TextField, Typography} from "@mui/material";
import {ArrowBack, ArrowLeft} from "@mui/icons-material";
import {Link} from "react-router-dom";
import {auth} from "../firebase/clientApp";

export const Recover = () => {
    const email = React.useRef(null)
    const [success, setSuccess] = React.useState(false)
    const [error, setError] = React.useState(false)
    const [loading, setLoading] = React.useState(false)

    const recoverPassword = async (e) => {
        e.preventDefault()
        setLoading(true)
        await auth.sendPasswordResetEmail(email.current.value)
            .then((userCredential) => {
                setSuccess(true)
            })
            .catch((error) => {
                setError(true)
            });
        setLoading(false)
    }
  return (
      <LoginPage text={'Inserisci la tua mail qui sotto per recuperare la password'}>
          <Container maxWidth={'sm'} sx={{pt:2}}>
              <Box sx={{pb:2}}>
                  <Button startIcon={<ArrowBack/>} variant={'outlined'} component={Link} to={'/login'}>
                        Torna alla login
                  </Button>
              </Box>
              <form onSubmit={recoverPassword}>
                  <TextField type={'email'} inputRef={email} fullWidth label={'Email'} variant={'standard'} sx={{pb:2}}/>
                  <Box sx={{textAlign:'center', pt:2}}>
                      {loading ? <CircularProgress/> : <Button variant={'contained'} type={'submit'}>
                          recupera password
                      </Button>}
                      {/*<Typography variant={'body2'} textAlign={'center'}>
                          <br/>Se non te le ricordi le tue credenziali inserisci l'e-mail alla quale hai ricevuto la comunicazione e reimposta la password
                      </Typography>*/}
                  </Box>
              </form>
              <Collapse in={success}>
                  <Typography sx={{pt:2}} color={'green'} variant={'body2'} textAlign={'center'}>
                      Email inviata correttamente
                  </Typography>
              </Collapse>
              <Collapse in={error}>
                  <Typography sx={{pt:2}} color={'red'} variant={'body2'} textAlign={'center'}>
                      Errore nell'invio della mail
                  </Typography>
              </Collapse>
          </Container>
      </LoginPage>
  )
}
