import React from "react";
import {
    Box,
    Button,
    Card,
    CardContent, CircularProgress,
    Container,
    Snackbar,
    Stack,
    TextField,
    Typography,
    useTheme
} from "@mui/material";
import {LoginPage} from "../layouts/LoginPage";
import {Link, useNavigate} from "react-router-dom";
import MuiAlert from "@mui/material/Alert";
import {auth} from "../firebase/clientApp";

const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export const Login = () => {
    const email = React.useRef(null)
    const password = React.useRef(null)
    const [loading, setLoading] = React.useState(false)
    const [error, setError] = React.useState(false)
    const navigate = useNavigate()

    const doLogin = async (e) => {
        e.preventDefault()
        setLoading(true)
        await auth.signInWithEmailAndPassword(email.current.value, password.current.value)
            .then((userCredential) => {
                navigate('/', {replace: true})
            })
            .catch((error) => {
                setError(true)
            });
        setLoading(false)
    }

    return (
        <LoginPage text={'Inserisci le tue credenziali per accedere'}>
            <Snackbar open={error} autoHideDuration={10000} onClose={() => setError(false)}>
                <Alert  onClose={() => setError(false)} severity="error" sx={{ width: '100%' }}>
                    Credenziali errate
                </Alert>
            </Snackbar>
            {
                error && <Typography variant={'body2'} textAlign={'center'}>
                    <br/>Se ti sei già registrato nelle precedenti sessioni puoi utilizzare le credenziali create in precedenza.
                    <br/>Se non le ricordi puoi reimpostare la password utilizzando l'email sulla quale hai ricevuto l'ultima comunicazione.
                </Typography>
            }
            <Container maxWidth={'sm'} sx={{pt:4}}>
                <form onSubmit={doLogin}>
                    <TextField type={'email'} inputRef={email} fullWidth label={'Email'} variant={'standard'} sx={{pb:2}}/>
                    <TextField type={'password'} inputRef={password} fullWidth label={'Password'} variant={'standard'} sx={{pb:2}}/>
                    <Box sx={{textAlign:'center', pt:2}}>
                        {loading ? <CircularProgress/> : <Button variant={'contained'} type={'submit'}>
                            accedi
                        </Button>}
                    </Box>
                </form>
            </Container>
            <Box sx={{textAlign:'center',pt:4}}>
                <Link to={'/recover'}>Password dimenticata?</Link>
            </Box>
        </LoginPage>
    )
}
