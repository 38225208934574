import React, {useEffect, useState} from "react";
import {
    Box,
    Button, CircularProgress,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Stack,
    Typography
} from "@mui/material";

export const Domanda = ({test, addAnswer, prevQuestion, nextQuestion, last, first, answer, currentQuestion, sending}) => {
    const [value, setValue] = useState("")
    const singleTestSubmit = (event) => {
        event.preventDefault()
        addAnswer(test.id, value)
        nextQuestion()
    }

    useEffect(() => {
        setValue(answer.answerId)
    }, [currentQuestion])

    return (
        <form onSubmit={singleTestSubmit}>
            <Typography variant={'h6'} textAlign={'center'} sx={{pb:2}}>
                {test.text}
            </Typography>
            <FormControl>
                <RadioGroup
                    aria-labelledby={test.id + "-label"}
                    name={test.id}
                    value={value}
                    sx={{gap: 1.5}}
                    onChange={(event) => setValue(event.target.value)}
                >
                    {
                        test.answers.map((answer, index) =>
                            <FormControlLabel key={index} value={answer.id} control={<Radio required/>} label={answer.text}/>
                        )
                    }
                </RadioGroup>
            </FormControl>
            <Stack direction={'row'} justifyContent={'space-between'} sx={{pt:3}}>
                <Box>
                    <Button variant={'outlined'} onClick={prevQuestion} disabled={first}>
                        Indietro
                    </Button>
                </Box>
                <Box>
                    {sending ? <CircularProgress/> : <Button variant={'contained'} type={'submit'}>
                        {last ? "Termina test" : "Prossima domanda"}
                    </Button>}
                </Box>
            </Stack>
        </form>
    )
}
