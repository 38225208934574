import React from "react";
import {Box, Typography} from "@mui/material";
import {WithFooter} from "../layouts/WithFooter";

export const Error = () => {
  return(
      <WithFooter>
          <Box>
              <img style={{width: '100%'}} src={require('../assets/images/header.jpg')}/>
          </Box>
          <Typography textAlign={'center'} color={'red'} variant={'h5'} fontWeight={'bold'}>
              Il link è errato
          </Typography>
      </WithFooter>
  )
}
