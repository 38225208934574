import React, {useEffect, useState} from "react";
import {Footer} from "../components/shared/Footer";
import {Box, Button, CircularProgress, Container, Stack, Tab, Tabs} from "@mui/material";
import {Header} from "../components/shared/Header";
import {NavLink} from "react-router-dom";
import {useUserStore} from "../store/userStore";
import {API_URL, CURRENT_SESSION_ID} from "../config";
import axios from "axios";
import {getAuthToken} from "../functions/getAuthToken";
import {auth} from "../firebase/clientApp";
import {useTestStore} from "../store/testStore";

const tabs = [
    {
        label: 'Webinar',
        path: '/'
    },
    {
        label: 'Test',
        path: '/test'
    },
    {
        label: 'Contenuti',
        path: '/contenuti'
    },
    {
        label: 'Area personale',
        path: '/area-personale'
    }
]

export const Platform = ({children, refresh}) => {
    const [value, setValue] = useState(0);
    const [loading, setLoading] = useState(true);
    const {initialize} = useUserStore()
    const {loadTest} = useTestStore()

    const handleChange = (_e, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        auth.onAuthStateChanged((user) => {
            getData().then()
        })
    }, [])

    const getData = async () => {
        setLoading(true)
        let token = await getAuthToken()
        let check = token.split(' ')
        if (check[1] !== 'undefined') {
            await axios.get(`${API_URL}/${CURRENT_SESSION_ID}/profile`, {headers: {Authorization: token}})
                .then(res => {
                    initialize(res.data.user)
                    loadTest(res.data.test)
                })
        }
        setLoading(false)
    }

    return (
        <Stack sx={{minHeight: '100vh', position: 'relative'}} justifyContent={'space-between'}>
            <Box>
                <Header/>
                <Box sx={{width: 'min(49.813rem, 100%)', margin: 'auto', p: 1, my: 10}}>
                    <img style={{width: '100%'}} src={require('../assets/images/header-2.jpg')}/>
                </Box>
                <Container maxWidth={'md'}>
                    <Stack direction={'row'} spacing={{xs: 0, sm: 2}} justifyContent={'center'} className={'tabs-link'}
                           sx={{maxWidth: '100%'}}>
                        {tabs.map((t, i) =>
                            <Box key={i} component={t.disabled ? 'a' : NavLink} to={t.path} style={{color:t.disabled ? '#c5c5c5' : ''}}>
                                {t.label}
                            </Box>
                        )}
                    </Stack>
                    {
                        loading ?
                            <Box sx={{textAlign: 'center', py:2}}>
                                <CircularProgress/>
                            </Box>
                            :
                            <Container sx={{py: 2}}>
                                {children}
                            </Container>
                    }
                </Container>
            </Box>
            <Footer/>
        </Stack>
    )
}
